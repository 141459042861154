<template>
  <div>
    <!-- Miscellaneous Charts -->
    <b-row
      class="match-height"
    >
      <b-col
        lg="4"
        cols="12"
        sm="12"
      >
        <customer-stats
          :key="`customerStats-${statisticsItemsCount}`"
          :loading="localStatsLoading"
          :statistics-items="statisticsItems"
        />
      </b-col>

      <b-col
        lg="8"
        cols="12"
        sm="12"
      >
        <!-- we have to unify this component later to not repeat ourselves for (cutomers, rides, scooters)-->
        <total-customers-stats />
      </b-col>
    </b-row>
    <!--/ Miscellaneous Charts -->

    <add-new-user
      :is-add-new-user-sidebar-active.sync="isAddNewSlideSidebarActive"
      :editing="user"
      @close="user = null"
    />

    <b-card id="users">
      <b-row>
        <b-col>
          <b-tabs v-model="tabs">
            <template #tabs-end />
            <b-tab>
              <template #title>
                <div>
                  <feather-icon icon="UsersIcon" />
                  <span>All</span>
                </div>
              </template>

              <new-table
                :data-source="dataSource"
                :export-file-name="exportFileName"
                :columns="columns"
                table-name="customers-list"
                router-link="view-customer"
              />

            </b-tab>
            <!-- <b-tab>
              <template #title>
                <div>
                  <feather-icon icon="FeatherIcon" />
                  <span>Subscriptions</span>
                </div>

                <b-badge
                  variant="light-primary"
                >
                  <img
                    src="@/assets/images/icons/customer/usa.png"
                  >
                  USA
                </b-badge>

              </template>

              <new-table
                :data-source="subscriptions_dataSource"
                :export-file-name="exportFileName"
                :columns="columns"
                table-name="customers-list"
                router-link="view-customer"
              />

            </b-tab> -->
            <b-tab>
              <template
                #title
              >
                <div>
                  <img
                    src="@/assets/images/icons/customer/colored-scooters.png"
                  >
                  Apollo Pro Testers
                </div>

              </template>

              <new-table
                :data-source="proTestersDataSource"
                :export-file-name="exportFileName"
                :columns="proTesterscolumns"
                table-name="pro-testers-list"
                router-link="pro-testers-list"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, VBTooltip, BCol, BRow, BTabs, BTab,
} from 'bootstrap-vue'
import AddNewUser from '@/views/users/AddNewUser.vue'
import NewTable from '@/views/new-table-ssr.vue'
import NewTableStatic from '@/views/new-table.vue'

import Moment from 'moment'
import CustomStore from 'devextreme/data/custom_store'
import CustomerStats from './statistics/customerStats.vue'
import totalCustomersStats from './statistics/totalCustomersStats.vue'

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    AddNewUser,
    BCol,
    BRow,
    NewTable,
    NewTableStatic,
    CustomerStats,
    totalCustomersStats,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      dataSource: null,
      surveys_dataSource: null,
      subscriptions_dataSource: null,
      proTestersDataSource: null,
      statisticsItemsCount:0,
      tabs: 0,
      filter: '',
      keys: 1,
      moment: Moment,
      exportFileName: `customersList_${Moment(new Date()).format('YYYY-MM-DD')}`,
      statsByMonth: {
        total: 0,
        series: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      statisticsTitle: 'Customer stats',
      statisticsItems: {
        usa: {
        },
        ca: {
        },
        subs: {
        },
        android: {
        },
        ios: {
        },
      },
      localStatsLoading: false,
      storeStatsLoading: false,
      loading: false,
      user: null,
      oldFilter: '',
      isAddNewSlideSidebarActive: false,
      fields: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'email', label: 'Mail', sortable: true },
        { key: 'username', label: 'Username', sortable: true },
        { key: 'store', label: 'Store', sortable: true },
        { key: 'is_app_user', label: 'App User?', sortable: false },
        { key: 'view_btn', label: 'View' },
      ],
      proTesterscolumns: [
        {
          label: 'Name',
          data: 'name',
        },
        {
          label: 'Email',
          data: 'email',
        },
        {
          label: 'Username',
          data: 'username',
        },
        {
          label: 'Mileage',
          data: 'mileage',
          calculateCellValue(data) {
            if (!data.mileage) return '-'
            return `${((data.mileage / 500) * 100).toFixed(0)}% - ${parseInt(data.mileage)}/500`
          },

        },
        {
          label: 'Store',
          data: 'main_store',
          custom_template: {
            template_name: 'protesters.store',
          },
        },
        {
          label: 'App',
          data: 'platform',
          custom_template: {
            template_name: 'protesters.platform',
          },
        },
        {
          label: 'Status',
          data: 'status',
          custom_template: {
            template_name: 'protesters.status',
          },
        },
      ],
      columns: [
        {
          label: 'Name',
          data: 'name',
        },
        {
          label: 'Email',
          data: 'email',
        },
        {
          label: 'Username',
          data: 'username',
        },
        {
          label: 'Store',
          data: 'main_store',
          custom_template: {
            template_name: 'customers.store',
          },
          custom_dropdown: {
            enabled: true,
            display: 'display',
            value: 'value',
            options: [
              {
                display: 'USA',
                value: 'usa',
              },
              {
                display: 'Canada',
                value: 'ca',
              },
              {
                display: 'Subscription',
                value: 'subs',
              },
              {
                display: 'App User',
                value: 'app',
              },
              {
                display: 'Unknown',
                value: 'unknown',
              },
            ],
          },

        },
        {
          label: 'Account created',
          data: 'joined_date',
          calculateCellValue(data) {
            if (!data.joined_date) return '-'
            const jdate = Moment(data.joined_date)
            return `${jdate.format('D MMMM YYYY')}`
            // return `${jdate.format('D MMMM YYYY')} (${jdate.fromNow()})`
          },
        },
        {
          label: 'Registered Country',
          data: 'registered_country',
        },
        {
          label: 'Country (last ride)',
          data: 'country_alias',
          custom_template: {
            template_name: 'customers.country',
          },
        },
        {
          label: 'PlatForm',
          data: 'platForm',
          data_type: 'display',
          value: 'value',
          custom_template: {
            template_name: 'customers.platform',
          },
          custom_dropdown: {
            enabled: true,
            display: 'display',
            value: 'value',
            options: [
              {
                display: 'iOS',
                value: 'ios',
              },
              {
                display: 'Android',
                value: 'android',
              },
              {
                display: 'React',
                value: 'react',
              },
              {
                display: 'None',
                value: 'None',
              },
            ],
          },
        },
      ],
      surveys_columns: [
        {
          label: 'Name',
          data: 'name',
        },
        {
          label: 'Email',
          data: 'email',
        },
        {
          label: 'Username',
          data: 'username',
        },
        {
          label: 'Mileage',
          data: 'mileage',
        },
        {
          label: 'Store',
          data: 'store',
        },
        {
          label: 'App',
          data: 'app',
        },
        {
          label: 'Status',
          data: 'status',
          custom_template: {
            template_name: 'surveys.status',
          },
        },
      ],
      page_name: 'customers-list'

    }
  },
  mounted() {
    this.$store.commit('menuConfig/SET_PAGE_TITLE', 'Customers')
    this.loadPageData(true)
    this.mountFilerData()
  },
  created() {
    // listen to event from app.vue page
    this.$eventHub.$on('selectedPageRangeChanged', this.loadPageData)

  },
  beforeDestroy() {
    // ignore to event from app.vue page
    this.$eventHub.$off('selectedPageRangeChanged', this.loadPageData)
  },
  methods: {
    loadPageData(showLoader = true,dates = null) {
      this.loadStats(showLoader,dates)
      this.loadLists(dates) 
    },
    loadLists(dates = null) {


    this.proTestersDataSource = new CustomStore({
      key: 'uuid',
      load: loadOptions => {


        if(dates) {
          loadOptions = this.$getPostData(loadOptions, dates)
        }


        if (loadOptions.filter !== undefined && loadOptions.filter.length > 0) {
          this.setFilterData('pro-testers-list', loadOptions)
        } else {
          this.setFilterData('pro-testers-list', null)
        }

        return this.$http.post(this.$resources.customers.proTesters, {
          search_filters: loadOptions,
        })
          .then(data => {
            this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_LOADER', false)
            return {
              data: data.data.data.rows,
              totalCount: data.data.data.pagination.total,
            }
          })
          .catch(error => { console.log(error) })
      },
    })


    this.dataSource = new CustomStore({
      key: 'id',
      load: loadOptions => {

        let postData = {
                search_filters: loadOptions,
        }

        const data = this.$getPostData(postData, dates)

        if (loadOptions.filter !== undefined && loadOptions.filter.length > 0) {
          this.setFilterData(this.page_name, loadOptions)
        } else {
          this.setFilterData(this.page_name, null)
        }


        if (loadOptions.sort !== null && loadOptions.sort.length > 0) {
          this.setSortData(this.page_name, loadOptions.sort)
        } else {
          this.setSortData(this.page_name, null)
        }

        return this.$http.post(this.$resources.customers.index, data)
        .then(data => {
            this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_LOADER', false)
            return {
              data: data.data.data.rows,
              totalCount: data.data.data.pagination.total,
            }
          })
          .catch(error => { console.log(error) })
      },
    })

    this.subscriptions_dataSource = new CustomStore({
      key: 'id',
      load: loadOptions => {
        if (loadOptions.filter !== undefined && loadOptions.filter.length > 0) {
          this.setFilterData('customers-list', loadOptions)
        } else {
          this.setFilterData('customers-list', null)
        }

        loadOptions.subscriptionsOnly = 1

        let postData = {
            search_filters: loadOptions,
        }
        const data = this.$getPostData(postData, dates)

        return this.$http.post(this.$resources.customers.index, data).then(data => ({
          data: data.data.data.rows,
          totalCount: data.data.data.pagination.total,
        })).catch(error => { console.log(error) })
      },
    })
    },
    mountFilerData() {
      this.columns = this.getFilterData('customers-list', this.columns)
    },

    convertToInternationalCurrencySystem(labelValue) {
      // Nine Zeroes for Billions
      // eslint-disable-next-line no-nested-ternary
      if (Number.isNaN(labelValue)) {
        return 0
      }

      return Math.abs(Number(labelValue)) >= 1.0e+9

        ? `${(Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2)}B`
      // Six Zeroes for Millions
      // eslint-disable-next-line no-nested-ternary
        : Math.abs(Number(labelValue)) >= 1.0e+6
          ? `${(Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2)}M`
        // Three Zeroes for Thousands
          : Math.abs(Number(labelValue)) >= 1.0e+3
            ? `${(Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2)}K`
            : Math.abs(Number(labelValue))
    },
    resetPassword(item) {
      this.loading = true
      this.$http.post(this.$resources.customers.resetPassword, {
        email: item.email,
      }).then(() => {
        this.loading = false
        this.$notify({ type: 'success', title: 'Password', text: 'Password sent successfully' })
      })
    },
    edit(item) {
      this.user = item
      this.isAddNewSlideSidebarActive = true
    },
    loadStats(showLoader, dates) {
      const data =  this.$getPostData({}, dates)

      if(showLoader) {
        this.localStatsLoading = true
      }
      this.$http.post(this.$resources.customers.calculations.stats, data).then(response => {
        const {
          data: {
            status, data: {
              usa, ca, subs, months, android, ios,
            },
          },
        } = response
        if (status === 200) {
          this.statsByMonth.series = months
          this.statsByMonth.total = usa + ca + subs
          this.statisticsItems.usa.amount = usa
          this.statisticsItems.ca.amount = ca
          this.statisticsItems.subs.amount = subs
          this.statisticsItems.android.amount = android
          this.statisticsItems.ios.amount = ios
          this.statisticsItemsCount++;
          this.localStatsLoading = false
        }
      })
    },
  },
}
</script>
<style lang="scss">
#users {
  min-height: 990px;
}
</style>
