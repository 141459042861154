<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >

    <template
      #default="{ hide }"
    >

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          v-if="editing && editing.id"
          class="mb-0"
        >
          View Customer
        </h5>
        <h5
          v-else
          class="mb-0"
        >
          Add New Customer
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1 mt-5"
      >
        <b-spinner label="Loading..." />
      </div>
      <!-- BODY -->
      <validation-observer
        v-else
        ref="refFormObserver"
      >
        <b-button
          class="m-1"
          @click="resetPassword"
        >
          Send Reset Password Email
        </b-button>
        <b-card class="m-1">
          <b-row>
            <b-col>
              <h1>{{ userData.firstName }} {{ userData.lastName }}</h1>
              <h4>{{ userData.email }} ({{ userData.username }})</h4>
              <hr>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="12">
              <b-row>
                <b-col md="4">
                  <div class="p-1">
                    <b>Country Code</b>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="p-1">
                    {{ userData.countryCode }}
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <b-col md="12">
              <b-row>
                <b-col md="4">
                  <div class="p-1">
                    <b>Status</b>
                  </div>
                </b-col>
                <b-col md="8">
                  <div class="p-1">
                    <b-badge
                      :variant="userData.blocked === 1 ? 'danger' : 'success'"
                      class="mr-1"
                    >
                      {{ userData.blocked === 0 ? 'Active' : 'Blocked' }}
                    </b-badge>

                    <b-badge :variant="userData.confirmed === 0 ? 'warning' : 'success'">
                      {{ userData.confirmed === 1 ? 'Confirmed' : 'Not Confirmed' }}
                    </b-badge>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <div class="p-1">
                <b>Scooters Serials</b>
              </div>
            </b-col>
            <b-col md="8">
              <div
                v-for="scooter in userData.scooters"
                :key="scooter.id"
                class="p-1"
              >
                {{ scooter.serial }}
              </div>
            </b-col>
          </b-row>

        </b-card>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BCol, BRow, BSpinner, BBadge, BCard, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import {
  required, alphaNum, email, url, mimes, integer, max, min,
} from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BSidebar,
    BSpinner,
    BCol,
    BRow,
    BBadge,
    BCard,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    editing: {
      type: Object,
      default() {
        return null
      },
    },
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      userData: {
        email: '',
        password: '',
        name: null,
      },
      max,
      min,
      required,
      alphaNum,
      email,
      url,
      mimes,
      integer,
    }
  },
  watch: {
    editing() {
      if (this.editing && this.editing.id) {
        this.loading = true
        this.$http.get(this.$resources.customers.fetch.replace(':id', this.editing.id)).then(response => {
          const { data: { status, data } } = response
          if (status === 200) {
            this.userData = { ...data.user, scooters: data.scooters }
            this.loading = false
          }
        })
      }
    },
  },
  methods: {
    resetPassword() {
      this.loading = true
      this.$http.post(this.$resources.customers.resetPassword, {
        email: this.userData.email,
      }).then(() => {
        this.loading = false
        this.$notify({ type: 'success', title: 'Reset password', text: 'Reset password message sent successfully' })
      })
    },
    resetForm() {
      this.userData = {
        email: '',
        password: '',
        name: null,
      }
      this.$emit('close', 1)
    },
    validateForm() {
      this.$refs.refFormObserver.validate()
        .then(success => {
          if (success) {
            this.loading = true
            const formData = new FormData()

            Object.keys(this.userData)
              .forEach(key => {
                if (this.userData[key]) formData.append(key, this.userData[key])
              })

            this.$http.post(this.editing && this.editing.id ? this.$resources.customers.update.replace(':id', this.editing.id) : this.$resources.customers.store, formData, {
              headers: {
                'Content-Type': 'multipart',
              },
            })
              .then(response => {
                const {
                  data: {
                    status,
                  },
                } = response

                if (status === 200) {
                  this.$notify({ type: 'success', title: this.editing ? 'Update user' : 'Add user', text: this.editing ? 'User updated successfully' : 'User added successfully' })
                  this.$emit('refetch-data', 1)
                  this.$emit('update:is-add-new-user-sidebar-active', false)
                } else if (status === 400) {
                  this.$notify({ type: 'danger', title: 'Validation error', text: 'Code is used' })
                  this.$refs.projectForm.setErrors({ code: ['Code is usd.'] })
                } else {
                  this.$notify({ type: 'danger', title: 'Server error', text: 'Service is under-maintenance currently' })
                }
                this.loading = false
              })
              .catch(() => {
                this.$notify({ type: 'danger', title: 'Server error', text: 'Service is under-maintenance currently' })
                this.loading = false
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
  .b-sidebar.sidebar-lg {
    width: 55rem;
  }
</style>
