<template>
  <b-card
    class="card-tiny-line-stats"
    body-class="pb-50"
  >
    <b-row v-if="showPeriodsPicker || timeInterval || showRangePicker" class="default-padding">
      <b-col class="time-options cols-5">
        <ul v-if="showPeriodsPicker" class="list-unstyled time-select-buttons">
          <li
            v-for="(time,index) in timeOptions"
            :key="index"
            :class="defaultType == time.value ? 'btn white-btn active' : 'btn white-btn'"
            @click="changeSelectedTime(time,'type')"
          >
            {{ time.option }}
          </li>
        </ul>
        <div
          v-if="timeInterval"
          class="widget-range"
        >
         <!-- its hard to replace in main time we can skip it for now while we will use page picker and repace it with page picker -->
          <b-dropdown
            right
            :html="selectedRange"
          >
            <b-dropdown-item
              v-for="(item, index) in rangeOptions"
              :key="index"
              @click="changeSelectedTime(item,'interval')"
            >
              {{ item.option }}
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div
          v-b-tooltip="'Total Rides'"
          class="total-amount rides-total-amount"
        >
          {{ ridesStatsTotalAmount }}
        </div>
        <div
          v-b-tooltip="'Total Mileage'"
          class="total-amount mileage-total-amount"
        >
          {{ mileageStatsTotalAmount }}
        </div>


        <VueDatePicker
          v-if="showRangePicker"
          v-model="selectedDate"
          class="pl-1"
          :max-date="maxDate"
          range
          fullscreen-mobile
          validate
          :format="dataFormat"
          placeholder="Choose date"
          clearable
          @onChange="dateChanged(selectedDate)"
      />

        
      </b-col>

    </b-row>

    <!-- chart -->
    <vue-apex-charts
      v-if="isLoaded"
      ref="statisticsChart"
      :options="{...totalRidesChart.chartOptions}"
      :series="series"
      :height="totalRidesChart.chartOptions.chart.height"
    />
    <div
      v-if="!isLoaded"
      class="d-flex justify-content-center mb-1 mt-5"
    >
      <b-spinner label="Loading..." />
    </div>
  </b-card>
</template>
<script>
import {
  BCard, BDropdownItem, BDropdown, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

import { VueDatePicker } from '@mathieustan/vue-datepicker';
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css';

import Moment from 'moment'

export default {
  components: {
    BCard,
    VueApexCharts,
    BDropdownItem,
    BDropdown,
    VueDatePicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    series: {
      type: Array,
      default() {
        return [{
          name: 'Total Rides',
          data: [4, 3, 10, 9, 29, 19, 22, 9, 12, 7, 19, 5, 13, 9, 17, 2, 7, 5],
        }]
      },
    },
    height: {
      type: String,
      default() {
        return '400px'
      },
    },
    loaded: {
      type: Boolean,
      default() {
        return true
      },
    },
    defaultTime: {
      type: String,
      default() {
        return 'ytd'
      },
    },
    defaultType: {
      type: String,
      default() {
        return 'monthly'
      },
    },
    defaultInterval: {
      type: String,
      default() {
        return 'ytd'
      },
    },
    timeInterval: {
      type: Boolean,
      default() {
        return false
      },
    },
    showRangePicker: {
        type: Boolean,
        default() {
          return false
        },
      },
      showPeriodsPicker: {
        type: Boolean,
        default() {
          return false
        },
      },
    colors: {
      type: Array,
      default() {
        return ['#000']
      },
    },
    showRides: {
      type: Boolean,
      default() {
        return true
      },
    },
    showMileage: {
      type: Boolean,
      default() {
        return true
      },
    },

    ridesStatsTotalAmount: {
      type: Number,
      default() {
        return null
      },
    },
    mileageStatsTotalAmount: {
      type: Number,
      default() {
        return null
      },
    },
    chartType: {
      type: String,
      default() {
        return ''
      },
    },
    // will be removed after data corrected
    roundNumber: {
      type: Number,
      default() {
        return null
      }
    },
    selectedDates: {
      type: Object,
      default() {
        return null
      }
    }
  },
  computed: {
    maxDate() {
      const currentDate =  new Date()

      return new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
      );
    }
  },
  data() {
    return {
      moment: Moment,
      dateFormat: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric' 
      },
      selectedDate: null,
      selected: this.defaultPeriod,
      type: null,
      interval: null,
      default_type: 'ytd',
      default_interval: 'monthly',
      isLoaded: this.loaded,
      mileageSeriesShown: true,  // will be removed after data corrected
      timeOptions: [
        {
          option: '1M',
          value: 'month',
          type: 'type',
        },
        {
          option: '1Y',
          value: 'year',
          type: 'type',
        },
        {
          option: 'YTD',
          value: 'ytd',
          type: 'type',
        },
        {
          option: 'ALL',
          value: 'all-time',
          type: 'type',
        },
      ],
      selectedDatesRange: null,
      selectedRange: 'day',
      rangeOptions: [
        {
          option: 'Daily',
          value: 'daily',
          type: 'interval',
        },
        {
          option: 'Weekly',
          value: 'weekly',
          type: 'interval',
        },
        {
          option: 'Monthly',
          value: 'monthly',
          type: 'interval',
        },
      ],
      show: false,
      totalRidesChart: {
        chartOptions: {
          // will be removed after data corrected
          roundNumber: this.roundNumber,
          chart: {
            id: 'lineChart',
            animations: {
              enabled: true,
            },
            markers: {
              size: 2,
            },
            toolbar: {
              show: true,
              tools: {
                download: true,
                selection: true,
                zoom: true,
                // zoomin: true,
                // zoomout: true,
                pan: true,
                reset: true,
                // customIcons: [{
                //   icon: '',
                //   index: 1,
                //   title: 'Redo',
                //   class: 'custom-redo-list-icon',
                //   click: () => {
                //     this.changeRangeItem(this.selected)
                //   },
                // }],
              },

            },
            height: this.height,
            type: 'line',
            dropShadow: {
              enabled: false,
              enabledOnSeries: undefined,
              top: 1,
              left: 1,
              blur: 3,
              color: this.colors,
              opacity: 0.35,
            },
            events: {
              mounted() {
                const resizeOb = new ResizeObserver((entries => {
                  const rect = entries[0].contentRect
                  const { height } = rect
                  if (document.querySelector('.lines .card-body') !== null) {
                    document.querySelector('.lines .card-body').style.height = `${height - 160}px`
                  }
                }))
                if (document.querySelector('.lines .card-body') !== null) {
                  resizeOb.observe(document.querySelector('.pie .card-body'))
                }
              },
            },
          },
          // xaxis: {
          //   tickAmount: 20,
          //   forceNiceScale: true,
          //   offsetX:0,
          //   type: 'category',
          //   labels: {
          //     formatter(value) {
          //       return value
          //     },
          //   },
          // },
          stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: this.colors,
            width: 3,
            dashArray: 0,
          },
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex]
              return [name, `${val.toFixed(1)}%`]
            },
          },
          legend: {
            show: false,
          },
        },
      },
      selectionitems: {
        day: {
          value: 'day',
          text: 'Daily',
        },
        week: {
          value: 'week',
          text: 'Weekly',
        },
        month: {
          value: 'month',
          text: 'Monthly',
        },
        year: {
          value: 'year',
          text: 'Yearly',
        },
        ytd: {
          value: 'ytd',
          text: 'YTD',
        },
        all: {
          value: 'all',
          text: 'All',
        },
      },
      newSeries: [],
      dataFormat: 'YYYY-MM-DD'
    }
  },
  watch: {
    loaded(newVal) {
      this.isLoaded = newVal
      this.changeSelectedRange()
    },
    showRides() {
      this.$refs.statisticsChart.toggleSeries('Rides')
      if(this.roundNumber > 1) this.changeYaxis() //will be removed after data corrected
    },
    showMileage() {
      this.mileageSeriesShown = this.$refs.statisticsChart.toggleSeries('Mileage')
      this.$emit('mileage-series-shown-fn', this.mileageSeriesShown) //will be removed after data corrected
      if(this.roundNumber > 1) this.changeYaxis() //will be removed after data corrected
    },
  },
  mounted() {
    this.interval = this.defaultInterval
    this.type = this.defaultType
  },
  updated() {
    this.changeToolTip()

    if(!this.showMileage){
      if(this.$refs.statisticsChart) {
        this.$refs.statisticsChart.hideSeries('Mileage')
      }
    }

    if(!this.showRides){
      if(this.$refs.statisticsChart) {
        this.$refs.statisticsChart.hideSeries('Rides')
      }
    }

  },
  methods: {
    dateChanged(modelData) {
      if(modelData) {
        this.selectedDatesRange = {
          start_date: modelData.start ? `${this.moment(modelData.start).format(this.dataFormat)}` : '',
          end_date: modelData.end ? `${this.moment(modelData.end).format(this.dataFormat)}` : ''
        }
        if(this.selectedDatesRange.start_date && this.selectedDatesRange.end_date) {
          this.$eventHub.$emit('selectedRangeChanged', this.selectedDatesRange);
        }
      } else {
        this.$eventHub.$emit('selectedRangeChanged');
      }
    },
    changeYaxis() {
      this.$refs.statisticsChart?.chart?.updateOptions({
        yaxis: {
              tickAmount: 20,
              type: 'category',
              labels: {
                formatter: (value, c) => {
                  let label = (this.mileageSeriesShown && (this.roundNumber && this.roundNumber > 1)) ? (value *  this.roundNumber ) : value;
                  return label ?  label.toFixed(1) : '' 
                },
              },
            },
        });
    },
    changeToolTip() {
      if (this.chartType === 'rides') {
        this.$refs.statisticsChart?.chart?.updateOptions({
          tooltip: {
            custom({
              series, seriesIndex, dataPointIndex, w,
            }) {
              let speed = series[1][dataPointIndex] / series[0][dataPointIndex]
              let val   = series[1][dataPointIndex]
              //will be changes after data corrected
              if(series[1].length > 0 && w.config.roundNumber && w.config.roundNumber !== undefined && w.config.roundNumber > 1) {
                val = val * w.config.roundNumber
                speed = (val / series[1][dataPointIndex]).toFixed(1)
              }



              if(isNaN(speed)) speed = 0 
              var rides = series[0][dataPointIndex] ? series[0][dataPointIndex].toFixed(1) : 0;
              var km = series[1][dataPointIndex] ? series[1][dataPointIndex].toFixed(1) : 0;
              speed = speed ? parseFloat(speed).toFixed(1) : 0;

              return (
                `<div class="custom-tooltip arrow_box">
                  ${w.config.series[0].data.length ? `<div class="series">
                      <div class="series-bullet" style="background-color: ${ w.config.series[0].color}"></div>
                      <div class="series-data">${w.config.series[0].name}: ${rides ? rides : 0} rides</div>
                  </div>`: ``}
                  ${w.config.series[1].data.length ? `<div class="series">
                      <div class="series-bullet" style="background-color: ${ w.config.series[1].color}"></div>
                      <div class="series-data">${w.config.series[1].name}: ${km ? km : 0} km</div>
                  </div>`: ``}
                  ${(w.config.series[0].data.length && w.config.series[1].data.length) ? `<div class="series">
                      <div class="series-bullet angle-icon"></div>
                      <div class="series-data">${speed ? speed : 0 } km/r</div>
                  </div>`: ``}
                </div>`
              )


            },
          },
          yaxis: {
            tickAmount: 20,
            type: 'category',
            labels: {
              formatter: (value, c) => {
                //will be changes after data corrected
                let label = (this.roundNumber && this.roundNumber > 1) ? (value *  this.roundNumber ) : value;
                return label ? label.toFixed(0) : 0 
              },
            },
          },

        })
      }
    },
    changeSelectedRange() {
      const loadedInterval = this.rangeOptions.find(option => option.value === this.defaultInterval)
      this.selectedRange = `${loadedInterval.option}<span class="caret-symbol"></span>`
    },
    changeSelectedTime(option, target) {
      if (this[target] === option.value) return
      this[target] = option.value
      this.selectedDatesRange = this.getDates(option.value)
      this.$eventHub.$emit('selectedRangeChanged', this.selectedDatesRange);
      this.$emit('reload-chart', this.type, this.interval, this.selectedDatesRange)
    },
    getDates(type) {
      let dates = null;
      switch(type) {
        case 'all-time':
            dates = null;
        break
        case 'year':
            dates = [this.moment().subtract(1,'year').format(this.dataFormat),  this.moment().format(this.dataFormat)];
        break
        case 'month':
            dates = [this.moment().subtract(1, 'month').format(this.dataFormat),  this.moment().format(this.dataFormat)];
        break
        case 'ytd':
            dates =  [this.moment().startOf('year').format(this.dataFormat), this.moment().format(this.dataFormat)];
        break
        default:
        dates = null;
      }

      let returnObject = {};

      if(dates) {
        if(dates[0]){
          returnObject.start_date = dates[0]
        }
        if(dates[1]){
          returnObject.end_date = dates[1]
        }
      }
      return returnObject
    }
  },
}
</script>
<style lang="scss">
.card-tiny-line-stats {


  .custom-tooltip {
    padding: 4px 8px;
    background: #2C2C2C;
    border-radius: 6px;
    color: #fff;
    .series {
      margin-bottom: 1px;
      display: flex;
      align-items: center;
      .series-bullet {
        width: 8px;
        height: 8px;
        float: left;
        border-radius: 50%;
        display: block;
        margin-right: 5px;
        &.angle-icon{
          height: 21px;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url('~@/assets/images/icons/ride/value-icon.png');
        }
      }
    }
  }
  
  .default-padding {
    margin-left: 0px !important;
  }
  .time-select-buttons {
    margin: 0;
    .white-btn {
      border:2px solid #C6C6C6;
      border-radius: 6px;
      margin-right: 10px;
      color:#1D1D1D;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.7px;
      padding: 4px 16px 4px 16px;
      &:hover {
       background-color: #dcdcdc;
       border-color: #dcdcdc;
      }
      &:active {
       background-color: #c6c6c6;
       border-color: #c6c6c6;
      }
      &.active {
       background-color: #c6c6c6;
       border-color: #c6c6c6;
      }
    }
  }

  .custom-redo-list-icon {
    background-image: url('~@/assets/images/icons/ride/redo-icon.svg') !important;
    background-size: 100% !important;
    background-repeat: no-repeat;
    &:hover {
      background-image: url('~@/assets/images/icons/ride/redo-icon-black.svg') !important;
    }
  }
.time-options {
  display: flex;
  justify-items: center;
}
.widget-range {
      .dropdown  {
        display: flex !important;
        border: 2px solid #C6C6C6 !important;
        border-radius: 8px !important;
        padding-top: 2px !important;
        float: left !important;
        height: 28px !important;
        width: fit-content !important;
        .dropdown-toggle {
          width: fit-content !important;
          color: #1D1D1D !important;
          margin-top: 0px !important;
          padding: 0px 0px 0px 8px !important;
          border-radius: 6px;
          height: 24px !important;
        }
      }

    .caret-symbol {
      display: block !important;
      height: 20px !important;
      width: 20px !important;
      float: right !important;
      background-image: url('~@/assets/images/icons/general-icons/caret.svg') !important;
    }
 }
}

.vd-picker-header--range {
  .vd-picker-header__date {
    font-size: 15px;
  }
} 
@media screen and (max-width: 460px) {
  .card-tiny-line-stats {
    .time-select-buttons {
      .white-btn {
        margin-right: 5px;
        margin-bottom: 5px;
      }
    }
  }
}

.v-calendar{
  .input-field {
    input {
      height: 34px;
    }
  }
}
</style>
