<template>
  <widget-loader
    v-if="loading"
    class="content-holder"
  />
  <b-card
    v-else
    class="customer-stats content-holder"
  >
    <div class="title">
      Customer stats
    </div>
    <div class="stats-container">
      <div
        class="sub-title"
      >
        Customers
      </div>
      <ul class="customers-states-list list-unstyled row">
        <li
          class="stats-item usa-stats"
        >
          <i class="stats-icon usa-icon" />
          <div class="stats-title">
            USA
          </div>
          <div class="stats-amount">
            {{  statisticsItems.usa.amount ? parseInt(statisticsItems.usa.amount, 0).toLocaleString() : 0}}
          </div>
        </li>

        <li class="stats-item ca-stats">
          <i class="stats-icon ca-icon" />
          <div class="stats-title">
            Canada
          </div>
          <div class="stats-amount">
            {{  statisticsItems.ca.amount ? parseInt(statisticsItems.ca.amount, 0).toLocaleString() : 0}}
          </div>
        </li>

        <li class="stats-item subs-stats d-none">
          <i class="stats-icon subs-icon" />
          <div class="stats-title">
            Subscription
          </div>
          <div class="stats-amount">
            {{  statisticsItems.subs.amount ? parseInt(statisticsItems.subs.amount, 0).toLocaleString() : 0}}
          </div>
        </li>

      </ul>
    </div>

    <div class="stats-container">
      <div class="sub-title">
        Users by platform
      </div>
      <ul class="customers-states-list customers-platform-list list-unstyled row">
        <li class="stats-platform-item usa-stats">
          <i class="stats-icon ios-icon" />
          <div class="stats-title">
            iOS users
          </div>
          <div class="stats-amount">
            {{  statisticsItems.ios.amount ? parseInt(statisticsItems.ios.amount, 0).toLocaleString() : 0}}
          </div>
        </li>

        <li class="stats-platform-item ca-stats">
          <i class="stats-icon android-icon" />
          <div class="stats-title">
            Android users
          </div>
          <div class="stats-amount">
            {{  statisticsItems.android.amount ? parseInt(statisticsItems.android.amount, 0).toLocaleString() : 0}}
          </div>
        </li>
      </ul>
    </div>

  </b-card>
</template>

<script>
import WidgetLoader from '@/views/components/WidgetLoader.vue'

export default {
  components: {
    WidgetLoader,
  },
  props: {
    statisticsItems: {
      type: Object,
      default() {
        return {}
      },
    },
    loading: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
}
</script>

<style lang="scss">
.content-holder {
  .widget-loader {
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
  .customer-stats {
      font-family: 'Montserrat';
      font-style: normal;
      color:#1D1D1D;
    .title {
        font-size: 18px;
        font-weight: 600;
    }
    .stats-container{
        padding:0px !important;
        margin-top: 12px !important;
        .sub-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 10px;
        }
    }
  }

  .customers-states-list {
    .stats-icon {
        display: block;
        width: 40px;
        height: 40px;
        background: #F3F3F3;
        border: 1px solid rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        float: left;
        margin-right: 16px !important;
        background-repeat: no-repeat;
        background-position: center;
        color: #808080;
        margin-bottom: 15px;
        &.usa-icon {
            background-image: url('~@/assets/images/icons/customer/usa.png') !important;
        }
        &.ca-icon {
            background-image: url('~@/assets/images/icons/customer/ca.png') !important;
        }

        &.subs-icon {
            background-image: url('~@/assets/images/icons/customer/hand.png') !important;
        }

        &.ios-icon {
            background-image: url('~@/assets/images/icons/ride/apple.png') !important;
        }
        &.android-icon {
            background-image: url('~@/assets/images/icons/ride/android-green.png') !important;
        }

    }
    .stats-amount {
      font-weight: 600;
      font-size: 18px;
      color: #111;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
    }
    .stats-item,.stats-platform-item {
        width:50%;
        padding-left: 12px;
      }

    }
    .customers-platform-list {
      .stats-item,.stats-platform-item {
        width:100%;
      }
    }

   @media screen  and (min-width: 991px) and (max-width: 1445px) {
    .customers-states-list{
      .stats-item:not(.stats-platform-item)  {
        width:100% !important;
      }
    }
  }

@media screen and (max-width: 991px){
  .customers-states-list {
    .stats-platform-item {
      width:50% !important;
    }
  }
}

@media screen and (max-width: 350px) {
    .customers-states-list {
      .stats-item,.stats-platform-item {
        width:100% !important;
      }
    }
}

</style>
