<template>
  <b-card class="total-customer-stats">
    <div class="pl-2">
      <div class="ml-1">
        <span class="title">
          Total Customers
        </span>
        <div class="subtitle">
          {{ moment(new Date()).format('YYYY-MM-DD') }}  <span class="mr-1"><span class="font-weight-bold">{{ CustomerstatsTotalAmount }}</span> Customer(s)</span>
        </div>
      </div>
    </div>
    <card-statistic-line-chart-colors-liner
      :series="totalCustomersList.series"
      :loaded="isLoaded"
      height="250px"
      :default-type="defaultType"
      :default-interval="defaultInterval"
      :time-interval="false"
      @reload-chart="loadCharts"
    />
  </b-card>
</template>
<script>
import CardStatisticLineChartColorsLiner from '@/views/components/statistics/CardStatisticLineChartColorsLiner.vue'
import Moment from 'moment'

export default {
  components: {
    CardStatisticLineChartColorsLiner,
  },
  props: {

    statistic: {
      type: Array,
      default() {
        return []
      },
    },
    customersStatsTotal: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  data() {
    return {
      moment: Moment,
      isLoaded: true,
      totalCustomersList: {},
      statisticsItemsList: this.statisticsItems,
      CustomerstatsTotalAmount: this.CustomerstatsTotal,
      defaultType: 'ytd',
      defaultInterval: 'monthly',
    }
  },
  mounted() {
    this.loadCharts()
  },
  created() {
    // listen to event from app.vue page
    this.$eventHub.$on('selectedPageRangeChanged', this.refreshChart)
  },
  beforeDestroy() {
    // ignore to event from app.vue page
    this.$eventHub.$off('selectedPageRangeChanged')
  },
  methods: {
    refreshChart(showLoader = true,dates = null, interval = null, period = null, applyDates = false) {
      this.defaultType = period;
      this.defaultInterval = interval
      this.loadCharts(showLoader, this.defaultType, this.defaultInterval, dates, applyDates)
    },
    loadCharts(showLoader, type = null, interval = null, dates = null, applyDates = false) {
      if(showLoader) {
        this.statsLoading = true
        this.isLoaded = false
      }

      const postData = {}
      if (dates && applyDates) {
        if (dates.start_date) {
          postData.start_date = dates.start_date
        }
        if (dates.end_date) {
          postData.end_date = dates.end_date
        }
      }


      this.defaultType = type ?? this.defaultType
      this.defaultInterval = interval ?? this.defaultInterval
      postData.interval = this.defaultInterval
      postData.type = 'customers_count'


      this.$http.post(this.$resources.customers.calculations.charts,  postData ).then(response => {
        const { data: { status, data: { stats } } } = response
        const dataValues = Object.values(stats)
        if (status === 200) {
          this.totalCustomersList = {
            series: [{
              data: dataValues.map(stat => ({ x: stat.label, y: stat.count })),
              name: 'Total Customers',
            }],
          }
          this.CustomerstatsTotalAmount = dataValues.reduce((total, stat) => total + stat.count, 0)
        }
        this.$store.commit('menuConfig/SET_TIME_PICKER_SELECTOR_LOADER', false)
        this.isLoaded = true
      })


    },
  },
}
</script>
<style lang="scss">
  .total-customer-stats {
    min-height: 330px;
      .card-body {
        padding: 20px 10px 0 10px !important;
    }
  }
</style>
